// utils/host-function.js
import dotenv from "dotenv";
dotenv.config();

const SITENAME = process.env.COUNTRY_DOMAIN || ""; // Fallback to empty string if undefined

// export function hostNameFunction(req = null) {
//   let domain;

//   if (req && req.headers && req.headers.host) {
//     domain = req.headers.host;
//   } else if (typeof window !== "undefined") {
//     domain = window.location.hostname;
//   } else {
//     return ""; // Return empty string or a default value if neither is defined
//   }

//   // const hostnamePart = domain.split(".")[0];
//   const hostnamePart = domain.split("-")[0];
//   let lanCode = "";
//   if (hostnamePart.length > 2 || hostnamePart == "el") {
//     lanCode = "";
//   } else if (hostnamePart.length == 2) {
//     lanCode = hostnamePart;
//   }
//   return lanCode;
// }

export function hostNameFunction(req = null) {
  let domain;

  if (req && req.headers && req.headers.host) {
    domain = req.headers.host;
  } else if (typeof window !== "undefined") {
    domain = window.location.hostname;
  } else {
    return ""; // Return empty string if neither is defined
  }

  // List of valid language codes (ISO 639-1)
  const validLangCodes = [
    "hi",
    "ar",
    "bn",
    "de",
    "es",
    "fr",
    "ja",
    "ko",
    "pa",
    "pt",
    "ru",
    "ta",
    "ur",
    "zh",
  ];

  // Extract subdomain
  const parts = domain.split(".");
  let subdomain = parts[0]; // First part before the first "."

  let langCode = "";

  // If subdomain contains a hyphen, check first part before hyphen
  if (subdomain.includes("-")) {
    const subParts = subdomain.split("-");
    if (validLangCodes.includes(subParts[0])) {
      langCode = subParts[0]; // Assign if it's a valid language code
    }
  } else if (validLangCodes.includes(subdomain)) {
    langCode = subdomain; // Direct match if it's a valid language code
  }

  return langCode;
}
