// dataSlice.js

import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "data",
  initialState: {
    popularAreas: [],
    img: 1,
  },
  reducers: {
    setPopularAreas: (state, action) => {
      state.popularAreas = action.payload;
    },
    setImg: (state, action) => {
      state.img = action.payload;
    },
  },
});

export const { setPopularAreas, setImg } = dataSlice.actions;

export default dataSlice.reducer;
