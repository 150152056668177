import { store } from "@/globalStates/store";
import "@/styles/globals.css"; // Adjust the import path as needed
import { Provider } from "react-redux";
import COUNTRY_NAME from "../locals/countryName.json";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import translations from "../locals/countryTranslation.json";
import ads from "../locals/ads.json";
import { hostNameFunction } from "../../utils/host-function";

export default function App({ Component, pageProps }) {
  const [countryName, setCountryName] = useState("");
  const [countryTranslations, setCountryTranslations] = useState({});
  const [gtmId, setGtmId] = useState("");
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== "undefined") {
      let currentUrl = window.location.href; // Full URL on the client side
      currentUrl = currentUrl.split(".com")[0] + ".com"; // Process URL
      const hostname = hostNameFunction(); // Extract hostname
      let baseUrl = currentUrl;
      if (hostname) {
        // Remove hostname from the URL
        baseUrl = currentUrl.replace(`${hostname}.`, "www.");
      }
      // Get the country from the URL or base URL
      const country = COUNTRY_NAME[baseUrl] || "Unknown Country";
      setCountryName(country);
      const matchedGtmId = ads[baseUrl] || "";

      if (country && translations[country]) {
        const language = hostname || "en"; // Default to 'en' if hostname is empty or 'www'
        setCountryTranslations(
          translations[country][language] || translations[country]["en"]
        );
      }

      if (matchedGtmId) {
        setGtmId(matchedGtmId);

        // Dynamically load Google Tag Manager
        const script = document.createElement("script");
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${matchedGtmId}`;
        document.head.appendChild(script);

        script.onload = () => {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", matchedGtmId);
        };
      } else {
        console.warn(`No GTM ID found for SITEURL: ${baseUrl}`);
      }
    }
  }, [router.asPath]);

  return (
    <Provider store={store}>
      <Component
        {...pageProps}
        countryName={countryName}
        countryTranslations={countryTranslations}
      />
      ;
    </Provider>
  );
}
